const esJSON = {
  LOGO: {
    VERSION: '1.0',
    VERSION_DESC: 'Primera versión',
    SYSTEM_NAME: 'Citas Tránsito Chihuahua'
  },
  MY_ACCOUNT: {
    HEADING: "Mi cuenta",
    SUBHEADING: "Información general y configuración de la cuenta",
    USER_DETAILS: "Detalles del usuario",
    USER_DETAILS_DESCRIPTION: "Administra la información de usuario",
    BTN_CHANGE_PASSWORD: "Cambiar contraseña",
    BTN_EDIT: "Editar",
    ACCOUNT_EDIT: "Editar perfil",
    ACCOUNT_EDIT_DESCRIPTION: "Formulario de edición del perfil",
    PROFILE_INFO_UPDATED: "Se ha actualizado la información del perfil",
    PROFILE_INFO_UPDATED_WITH_ERROR: "Se ha actualizado la información del perfil, pero, ocurrió un error al mostrar los datos actualizados.",
    CHANGE_PASSWORD_HEADING: "Cambiar contraseña",
    CHANGE_PASSWORD_SUBHEADING: "Formulario para cambiar la contraseña",
  },
  LOGIN: {
    HEADING: "Iniciar sesión",
    SUBHEADING: "Rellena los campos a continuación para iniciar sesión",
    REGISTER: "Registrarse",
    DONT_YOU_HAVE_AN_ACCOUNT: "¿No tienes una cuenta?",
    LOST_PASSWORD: "¿Olvidaste tu contraseña?"
  },
  REGISTER: {
    HEADING: "Registrar cuenta",
    SUBHEADING: "Completa los campos a continuación para crear una cuenta nueva.",
    BACK_TO: "Regresar a",
    USER_REGISTERED: "Se ha registrado el usuario"
  },
  LANGUAGE_SWITCHER: {
    HEADING: "Lenguage",
    SUBHEADING: "Cambia el lenguaje"
  },
  RECOVER_PASSWORD: {
    HEADING: "Recuperar contraseña",
    SUBHEADING: "Introduce tu correo electrónico para reiniciar tu contraseña",
    BACK_TO_LOGIN: "Quieres volver a iniciar sesión?",
    INSTRUCTIONS: "Las instrucciones para recuperar tu contraseña se han enviado a tu correo electrónico.",
    CHECK_MAIL: "Revisa tu correo electrónico para recuperar tu contraseña",
    TO_LOGIN: "Ir a iniciar sesión"
  },
  ROLES: {
    HEADING: "Roles",
    SUBHEADING: "Administración de los roles y permisos",
    CREATE: "Crear rol",
    CREATE_DESC: "Completa el formulario para crear un rol",
    EDIT: "Editar rol",
    EDIT_DESC: "Completa el formulario para editar el rol",
    SHOW: "Detalles del rol",
    SHOW_DESC: "Información completa del rol seleccionado",
    DELETE: "Borrar rol",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el rol seleccionado?",
    DELETED: "Se ha borrado el rol seleccionado",
    SEARCH: "Buscar por nombre o descripción",
    PERMISSIONS: "Permisos",
    SAVED: "Se ha guardado el rol.",
    EDITED: "Se ha editado el rol."
  },
  USERS: {
    HEADING: "Usuarios",
    SUBHEADING: "Administración de los usuarios",
    CREATE: "Crear usuario",
    CREATE_DESC: "Completa el formulario para crear un usuario",
    EDIT: "Editar usuario",
    EDIT_DESC: "Completa el formulario para editar el usuario",
    SHOW: "Detalles del usuario",
    SHOW_DESC: "Información completa del usuario seleccionado",
    DELETE: "Borrar usuario",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el usuario seleccionado?",
    DELETED: "Se ha borrado el usuario seleccionado",
    SEARCH: "Buscar por nombre de usuario o correo electrónico",
    ACTIVE: {
      0: 'Desctivado',
      1: 'Activado'
    }
  },
  RESET_PASSWORD: {
    HEADING: "Reiniciar contraseña",
    SUBHEADING: "Llena los campos para reiniciar tu contraseña",
    INSTRUCTIONS: "Ahora puedes iniciar sesión con tu nueva contraseña",
    SUCCESS: "Se ha reiniciado la contraseña",
    TO_LOGIN: "Ir a iniciar sesión"
  },
  EMAIL_VERIFICATION: {
    HEADING: "Verificación de correo",
    SUBHEADING: "Es necesario verificar tu correo electrónico para el uso del sistema. \n Al momento de registrarte se ha enviado un correo de verificación, en caso de no haberlo recibido puedes solicitar otro pulsando el siguiente botón.",
    BUTTON: "Solicitar un correo de verificación",
    SUCCESS: "Se ha enviado el correo de verificación a la dirección registrada"
  },
  VERIFY_EMAIL: {
    HEADING: "Verificación de correo",
    SUBHEADING: "Es necesario que el correo electrónico sea verificado para hacer uso del sistema",
    VERIFYING: "Verificando correo electrónico",
    VERIFIED: "Se ha verificado el correo electrónico con éxito, serás redirigido al inicio",
    VERIFIED_ERROR: "Hubo un error verificando tu correó electrónico, puedes solicitar un nuevo correó de verificacón en el siguiente botón",
    VERIFY: "Verificar correo electrónico",
    BUTTON: "Ir a solicitar un correo de verificación"
  },
  THEME_SETTINGS: {
    CHOOSE_LAYOUT: "Selecciona la distribución",
    CHOOSE_THEME: "Selecciona el tema",
    ADMIN_PANEL_LAYOUT: "Distribución del panel de administrador",
    ADMIN_PANEL_LAYOUT_DESCRIPTION: "Cambia el acomodo de la información",
    ADMIN_PANEL_THEME: "Tema del panel de administrador",
    ADMIN_PANEL_THEME_DESCRIPTION: "Cambia los colores",
    THEMES: {
      PURE_LIGHT_THEME: "Pure Light",
      GRAY_GOOSE_THEME: "Gray Goose",
      PURPLE_FLOW_THEME: "Purple Flow"
    },
    LAYOUTS: {
      EXTENDED_SIDEBAR: "Extended Sidebar",
      ACCENT_HEADER: "Accent Header",
      ACCENT_SIDEBAR: "Accent Sidebar",
      BOXED_SIDEBAR: "Boxed Sidebar",
      COLLAPSED_SIDEBAR: "Collapsed Sidebar",
      BOTTOM_NAVIGATION: "Bottom Navigation",
      TOP_NAVIGATION: "Top Navigation"
    },
  },
  FIELDS: {
    USERNAME: "Usuario",
    EMAIL: "Correo electrónico",
    NAME: "Nombre",
    LASTNAME: "Apellido",
    CURRENT_PASSWORD: "Contrseña actual",
    PASSWORD: "Contraseña",
    PASSWORD_CONFIRMATION: "Confirmación de contraseña",
    REMEMBER: "Recuerdame",
    DESCRIPTION: "Descripción",
    ID: "Id",
    CREATED_AT: "Creado",
    UPDATED_AT: "Actualizado",
    ROLE: "Rol",
    ROLE_ID: "Rol",
    ACTIVE: "Estado",
    EMAIL_VERIFIED_AT: 'Verificado',
    MUNICIPALITY: 'Municipio',
    QUOTES: "Citas",
    WEEKS: "Se repite",
    OPENING_TIME: "Hora de apertura",
    CLOSING_TIME: "Hora de cierre",
    MODULE: 'Módulo',
    LICENCE_TYPE: 'Tipo de licencia',
    FORMALITY: 'Trámite',
    ALL: "Todos",
    ALL_POSSIBLE_TIMES: "Todas las veces posibles",
    AND: " y ",
    DATE_AVAILABILITY_DETAILS: "Disponibilidad de citas",
    AVAILABLE_DAYS: "Disponibilidad de citas",
    LAST_NAME: "Primer Apellido",
    SECOND_LAST_NAME: "Segundo Apellido",
    CURP: "CURP",
    ADDRESS: "Dirección",
    RFC: "RFC",
    SEX: "Sexo",
    BIRTHDATE: "Fecha de nacimiento",
    MARITAL_STATUS: "Estado civil",
    NATIONALITY_ID: "Nacionalidad",
    NATIONALITY: "Nacionalidad",
    PHONE: "Teléfono",
    OCCUPATION: "Ocupación",
    EMERGENCY_CONTACT: "Contacto de emergencia",
    MINOR_TUTOR: "Tutor",
    CITIZEN_FOREIGNS: "Extranjeros",
    FOLIO_ID: 'Folio',
    DATE: 'Fecha',
    TIME: 'Hora',
    ASSISTANCE: 'Asistencia',
    APPOINTMENT_TYPE: 'Estado',
    DAY: "Día",
    ALL_EXCEPT_FIRST_TIME: "Todos excepto primera vez",
    USER: 'Usuario',
    TABLE: 'Tabla modificada',
    INI_DATE: 'Fecha de inicio',
    FIN_DATE: 'Fecha final',
    AFTER: 'Guardado',
    BEFORE: 'Antes de guardar',
    DATE_AVAILABILITY_DETAILS_EXCEPTION: 'Excepciones en disponibilidad de citas',
  },
  VALIDATION: {
    REQUIRED: "El campo es requerido.",
    EMAIL: "El campo debe ser un correo electrónico válido.",
    MIN_ONE: "Es necesario seleccionar al menos 1.",
    MAX_OPENING_TIME: "La hora de apertura debe ser menor a la hora de cierre",
    MIN_CLOSING_TIME: "La hora de cierre debe ser mayor a la hora de apertura"
  },
  GENERAL: {
    CANCEL: "Cancelar",
    LOGOUT: "Cerrar sesión",
    CLOSE: "Cerrar",
    NO_RESULTS: "No se han encontrado resultados",
    SEARCH: "Buscar",
    ACTIONS: "Acciones",
    GO_TO_HOMEPAGE: "Volver a inicio",
    BULK_ACTIONS: "Acciones masivas",
    BULK_DELETE: "Borrar seleccionados",
    SELECT_BETWEEN_VIEWS: "Selecciona la distribución para mostrar la lista entre una tabla y tarjetas.",
    RESET: 'Reiniciar formulario',
    ENABLE: "Activo",
    DISABLED: "Desactivado"
  },
  STATUS: {
    404: {
      MESSAGE: "La página que buscas no existe."
    },
    403: {
      MESSAGE: "No estás autorizado para realizar esta acción."
    },
    401: {
      MESSAGE: "No se ha iniciado sesión o la sesión ha caducado"
    }
  },
  SETTINGS: {
    HEADING: 'Configuración',
    SUBHEADING: 'Configuración del sistema',
    CREATE: "Crear usuario",
    CREATE_DESC: "Completa el formulario para crear un usuario",
    EDIT: "Editar usuario",
    EDIT_DESC: "Completa el formulario para editar el usuario",
    SHOW: "Detalles del usuario",
    SHOW_DESC: "Información completa del usuario seleccionado",
    DELETE: "Borrar usuario",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el usuario seleccionado?",
    DELETED: "Se ha borrado el usuario seleccionado",
    SEARCH: "Buscar por nombre de usuario o correo electrónico",
    SYSTEM_ADDRESSES_UPLOAD: 'Excel de códigos postales',
    SYSTEM_ADDRESSES_UPLOAD_DESC: 'Es necesario importar el archivo de códigos postales, puedes encontrarlo en el siguiente enlace: ',
    ADDRESSES: 'Códigos postales',
    SYSTEM_ADDRESSES_SUBMIT: 'Guardar archivo'
  },

  "LICENCE-TYPES": {
    HEADING: 'Tipos de licencia',
    SUBHEADING: 'Diferentes tipos de licencia',
    CREATE: "Crear tipo de licencia",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar tipo de licencia",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  MODULES: {
    HEADING: 'Módulos',
    SUBHEADING: 'Configuración de módulos',
    CREATE: "Crear módulo",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar el módulo",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  FORMALITIES: {
    HEADING: 'Trámites',
    SUBHEADING: 'Lista de trámites',
    CREATE: "Crear trámite",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar trámite",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  IDENTIFICATIONS: {
    HEADING: 'Identificaciones',
    SUBHEADING: 'Lista de identificaciones',
    CREATE: "Crear identificación de ciudadano",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar identificacion de ciudadano",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  "ADDRESS-VERIFICATION-DOCUMENTS": {
    HEADING: 'Documentos de verificación',
    SUBHEADING: 'Lista de documentos de verificación',
    CREATE: "Crear documento de verificación",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar documento de verificación",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  NATIONALITIES: {
    HEADING: 'Nacionalidades',
    SUBHEADING: 'Lista de nacionalidades',
    CREATE: "Crear nacionalidad",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar nacionalidad",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  "DATES-AVAILABILITY": {
    HEADING: "Disponibilidad de citas",
    SUBHEADING: 'Disponibilidad de citas en los modulos',
    CREATE: "Agregar disponibilidad de citas",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar disponibilidad de citas",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar",
    'WEEKS-TEXT': {
      1: "Primera semana",
      2: "Segunda semana",
      3: "Tercera semana",
      4: "Cuarta semana",
    },
    'DAY-TEXT': {
      Mon: 'Lunes',
      Tue: 'Martes',
      Wed: 'Miércoles',
      Thu: 'Jueves',
      Fri: 'Viernes',
      Sat: 'Sábado',
      Sun: 'Domingo'
    }
  },
  CITIZENS: {
    HEADING: 'Ciudadanos',
    SUBHEADING: 'Lista de ciudadanos',
    CREATE: "Registrar ciudadano",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar ciudadano",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  APPOINTMENTS: {
    HEADING: 'Listado de citas',
    SUBHEADING: 'Lista de citas registradas',
    SEARCH: 'Buscar por folio, curp o nombre',
    EXPORT: 'Exportar a excel'
  },
  DASHBOARD: {
    HEADING: 'Listado de citas'
  },
  "NONWORKING-DAYS": {
    HEADING: 'Días no laborales',
    SUBHEADING: 'Lista de días no laborales',
    CREATE: "Registrar día no laboral",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar día no laboral",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar"
  },
  "CHANGE-LOG": {
    HEADING: 'Historial de cambios',
    SUBHEADING: 'Historial de cambios registrados',
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
  },

  "DATES-AVAILABILITY-EXCEPTION": {
    HEADING: "Excepcion en disponibilidad de citas",
    SUBHEADING: 'Excepciones en la disponibilidad de citas en los modulos',
    CREATE: "Agregar excepcion en disponibilidad de citas",
    CREATE_DESC: "Completa el formulario",
    EDIT: "Editar excepcion en disponibilidad de citas",
    EDIT_DESC: "Completa el formulario para editar",
    SHOW: "Detalles",
    SHOW_DESC: "Información completa",
    DELETE: "Borrar",
    DELETE_CONFIRM_MESSAGE: "¿Realmente desea borrar el elemento?",
    DELETED: "Se ha borrado el elemento seleccionado",
    SEARCH: "Buscar",
  },

  "DUPLICATED-APPOINTMENT":{
    TITLE: "Cita ya programada para este tipo de licencia",
    INFO:"Usted ya cuenta con una cita programada para éste tipo de licencia de conducir, puede cancelar su cita al inicio de ésta página seleccionando el trámite \"CANCELAR CITA\", capturando su folio de cita que se encuentra en su hoja de confirmación que le fue enviada por correo, y después puede programar una nueva cita, o si requiere más información puede comunicarse al teléfono:",
    CONTACT_DATA_RENOVATION:"Renovación de licencia:",
    CONTACT_DATA_RENOVATION_CH:"Cd. Chihuahua: 614 429 3300 Ext. 20511",
    CONTACT_DATA_RENOVATION_JU:"Cd. Juárez: 656 629 3300 Ext. 55254",
    CONTACT_DATA_FIRSTIME: "Licencia por primera vez:",
    CONTACT_DATA_FIRSTIME_CH: "Cd. Chihuahua: 614 429 3300 Ext. 20963 y 20964",
    CONTACT_DATA_FIRSTIME_JU: "Cd. Juárez: 656 629 3300 Ext. 55254",
    BUTTON: "Aceptar"
  }
};

export default esJSON;
